import axios from 'axios';
import { GetAxiosConfig } from './AuthService';
import CONFIG from 'config';

export class ProduzentenService {
	async getAll() {
		return axios.get(CONFIG.ServerUrl + '/Produzenten', await GetAxiosConfig()).then(res => res.data);
	}

	async getFromId(id) {
		return axios.get(CONFIG.ServerUrl + '/Produzent/' + id, await GetAxiosConfig()).then(res => res.data);
	}

	async set(kunde) {
		return axios.post(CONFIG.ServerUrl + '/Produzent/Set', kunde, await GetAxiosConfig()).then(res => res.data);
	}

	async delete(id, targetId) {
		return axios.get(CONFIG.ServerUrl + '/Produzent/Delete/' + id + '/' + targetId, await GetAxiosConfig()).then(res => res.data);
	}
}